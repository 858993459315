import './App.css';
import React from 'react';


function Endpoint(props){
  return (
    <button className='endpointSelector' disabled={props.disabled} onClick={props.onClick} >
      { props.name }
    </button>
  )
}

function Account(props) {
  let number = props.number
  if (!props.number) {
    number = "No Account Number Found"
  }
  return (
    <div onClick={props.onClick}>
      <input type='radio' name='account' id={props.accountId} value={props.accountId} />
      <label htmlFor={props.accountId}>{ number }</label>
    </div>
  )
}

function Output(props) {
  return (
    <pre className='apiResult'>
      {props.result}
    </pre>
  );
}

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAccount: undefined,
      lastRawResponse: undefined,
      awaitingRequestHandler: undefined,
      postAccount: false,
      accounts: [],
    };
    this.endpoints = [
      {'name': 'Designate Agency (List Accounts)', 'url': '/agency', 'prefix': false, 'method': 'post', 'disabled': false},
      {'name': 'Get Service Account Info', 'url': '', 'prefix': true, 'method': 'get', 'disabled': true},
      {'name': 'Get Latest Billing Info', 'url': '/billing/current', 'prefix': true, 'method': 'get', 'disabled': true},
      {'name': 'Get Billing History', 'url': '/billing/history', 'prefix': true, 'method': 'get', 'disabled': true},
    ];
    this.lastResponse = undefined
  };

  handleEndpointClick(method, url, prefix) {
    this.sendRequest(method, url, prefix)
  }

  handleAccountClick(event, accountId) {
    event.setState((state, props) => {
      return {
        postAccount: true,
        selectedAccount: accountId
      }
    })

  }

  handleEvent(event) {
    this.setState((state, props) => {
      return {
        lastRawResponse: JSON.stringify(event.target.response, null, 2)
      }
    })
    if ('data' in event.target.response) {
      this.lastResponse = event.target.response.data
      if (event.target.responseURL.includes('/agency')) {
        this.loadAccount()
      }
    } else {
      alert(event.target.response.error)
    }
  }

  loadAccount() {
    this.setState((state, props) => {
      return {accounts: this.lastResponse}
    })
  }

  sendRequest(method, url, prefix) {
    const xhr = new XMLHttpRequest()
    const base = 'https://csgockegp3.execute-api.us-east-1.amazonaws.com/Prod'
    url = base + (prefix ? '/account/service/' + this.state.selectedAccount : '' ) + url

    xhr.open(method, url)
    xhr.addEventListener('load', this)
    xhr.setRequestHeader('Authorization', 'Bearer ' + window.localStorage.getItem('access_token'))
    xhr.responseType = 'json';
    xhr.send()
  }

  render() {
    const endpoints = this.endpoints.map((endpoint) =>
      <Endpoint
        name={endpoint.name}
        key={endpoint.url}
        onClick={() => this.sendRequest(endpoint.method, endpoint.url, endpoint.prefix)}
        disabled={endpoint.disabled && !this.state.postAccount} />
    );
    const accounts = this.state.accounts.map((account) =>
      <Account
        number={account.billAccountNumber}
        key={account.accountId}
        accountId={account.accountId}
        onClick={() => this.handleAccountClick(this, account.accountId)} />
    );

    return (
      <div class="content">
        <div class="heading">
          <h1>Ameren for Humans</h1>
        </div>
        <div>
          {endpoints}
        </div>
        <div class="account-header">
          <br/>
          <b>Accounts (by Utility Account Number)</b>
          {accounts}
        </div>
        <div class="result-content">
          <b>Raw Scrape Results</b>
          <Output result={this.state.lastRawResponse}/>
        </div>
      </div>
    );
  }
}

export default App;
